import { Box } from "@mui/material";


type EnterDimFrameProps = {
    children: React.ReactNode;
    logo?: string;
    instruction: string;
}

export const EnterDimFrame:React.FC<EnterDimFrameProps> = ({children, logo, instruction}) => {

    const onClickPrev = () => {
        console.log("Back");
    }

    const onClickNext = () => {
        console.log("Next");
    }

    return (
       <div>
       <h4>{instruction}</h4>

        {logo && <img  src={logo} height={180}/>}
        <Box sx={{display: 'flex', alignContent: 'center', justifyItems: 'center'}}>

        {children}
        </Box>
        </div> 
    )
}

