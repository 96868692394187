import { Grid, TextField } from "@mui/material";
import { AppFrame } from "../components/AppFrame"
import { BottomNav } from "../components/BottomNav";
import { EnterDimFrame } from "../components/EnterDimFrame";
import { useNavigate } from "react-router-dom";
import logo from '../images/JB.png';

export const EnterWK60P1 = () => {

    const navigate = useNavigate();

    const onClickPrev = () => {
        navigate(-1)
    }

    const onClickNext = () => {
        navigate("/enterWK60p2")
    }

    return (
        <AppFrame>
            <EnterDimFrame instruction="Geben Sie die gemessenen Strecken und Winkel in die WK60 ein">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Strecke J - A [m]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                        <TextField id="outlined-basic" label="Winkel J - A [°]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Strecke J - B [m]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                        <TextField id="outlined-basic" label="Winkel J - B [°]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Strecke J - C [m]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                        <TextField id="outlined-basic" label="Winkel J - C [°]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField id="outlined-basic" label="Strecke J - D [m]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                        <TextField id="outlined-basic" label="Winkel J - D [°]" variant="outlined" sx={{ marginTop: 1, width: 150 }} />
                    </Grid>
                </Grid>
                
            </EnterDimFrame>
            <BottomNav onClickPrev={onClickPrev} onClickNext={onClickNext} />

        </AppFrame>
    )
}
