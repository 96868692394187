
import React from 'react';
import Grid from '@mui/material/Grid';
import { TopNav } from './TopNav';

type AppFrameProps = {
    children: React.ReactNode;
}

export const AppFrame:React.FC<AppFrameProps> = ({ children }) => {
    return (
        <div>
           
            <TopNav/>
                
        {children}
           

           
        </div>
    )

    // return (
    //     <Grid container>
    //         <Grid item xs={0} sm={4} sx={{display: { xs: 'none', sm: 'block' }}} >
                
    //         </Grid>
           
           
    //         <Grid item xs={12} sm={4} marginLeft={0.5} marginRight={0.5}>
    //         <TopNav/>
                
    //     {children}
    //         </Grid>


    //         <Grid item xs={0} sm={4} sx={{display: { xs: 'none', sm: 'block' }}} >
                
    //         </Grid>
    //     </Grid>
    // )
}