import { Grid, TextField } from "@mui/material";
import { AppFrame } from "../components/AppFrame"
import { BottomNav } from "../components/BottomNav";
import { EnterDimFrame } from "../components/EnterDimFrame";
import { useNavigate } from "react-router-dom";
import logo from '../images/EnterModel.png';


export const IntroEnterWK60 = () => {

    const navigate = useNavigate();

    const onClickPrev = () => {
        navigate(-1)
    }

    const onClickNext = () => {
        navigate("/enterWK60p1")
    }

    return (
        <AppFrame>
            <EnterDimFrame logo={logo} instruction="Alle Messdaten sind aufgenommen. Geben Sie die Messdaten nun in die WK60 ein.">
           
                <h1></h1>
            </EnterDimFrame>
            <BottomNav onClickPrev={onClickPrev} onClickNext={onClickNext} />

        </AppFrame>
    )
}
