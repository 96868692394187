import { Grid, TextField } from "@mui/material";
import { AppFrame } from "../components/AppFrame"
import { BottomNav } from "../components/BottomNav";
import { EnterDimFrame } from "../components/EnterDimFrame";
import { useNavigate } from "react-router-dom";
import logo from '../images/JB.png';

export const EnterJB = () => {

    const navigate = useNavigate();

    const onClickPrev = () => {
        navigate(-1)
    }

    const onClickNext = () => {
        navigate("/introEnterWK60")
    }

    return (
        <AppFrame>
            <EnterDimFrame logo={logo} instruction="Messen Sie die Strecke J - B">
            <Grid container spacing={4}>
                    <Grid item xs={12}>
                <TextField id="outlined-basic" label="Strecke J - B [m]" variant="outlined" sx={{ marginTop: 1 }} />
                    </Grid>
                    <Grid item xs={12}>
                <TextField id="outlined-basic" label="Winkel J - B [°]" variant="outlined" sx={{ marginTop: 1 }} />
                    </Grid>                   
                </Grid>
                
            </EnterDimFrame>
            <BottomNav onClickPrev={onClickPrev} onClickNext={onClickNext} />

        </AppFrame>
    )
}
