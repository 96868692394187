import { Grid, TextField } from "@mui/material";
import { AppFrame } from "../components/AppFrame"
import { BottomNav } from "../components/BottomNav";
import { EnterDimFrame } from "../components/EnterDimFrame";
import { useNavigate } from "react-router-dom";
import logo from '../images/JA.png';


export const EnterJA = () => {

    const navigate = useNavigate();

    const onClickPrev = () => {
        navigate(-1)
    }

    const onClickNext = () => {
        navigate("/enterJB")
    }

    return (
        <AppFrame>
            <EnterDimFrame logo={logo} instruction="Messen Sie die Strecke J - A">
            <Grid container spacing={4}>
                    <Grid item xs={12}>
                <TextField id="outlined-basic" label="Strecke J - A [m]" variant="outlined" sx={{ marginTop: 1 }} />
                    </Grid>
                    <Grid item xs={12}>
                <TextField id="outlined-basic" label="Winkel J - A [°]" variant="outlined" sx={{ marginTop: 1 }} />
                    </Grid>                   
                </Grid>
                
            </EnterDimFrame>
            <BottomNav onClickPrev={onClickPrev} onClickNext={onClickNext} />

        </AppFrame>
    )
}
