import { Checkbox, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import { AppFrame } from "../components/AppFrame"
import { BottomNav } from "../components/BottomNav";
import mainLogo from '../images/test1.png';
import { EnterDimFrame } from "../components/EnterDimFrame";
import { useNavigate } from "react-router-dom";


export const MachinePositioning = () => {

    const navigate = useNavigate();

    const onClickPrev = () => {
        navigate(-1)
    }

    const onClickNext = () => {
        navigate("/setupLaser")
    }

    return (
        <AppFrame>
            <EnterDimFrame logo={mainLogo} instruction="Bewegen Sie den Bagger in folgende Position">
                <Grid container spacing={4}>
                    <Grid item xs={4}>

                    </Grid>
                    <Grid item xs={4} >
                        <FormGroup >
                            <FormControlLabel control={<Checkbox defaultChecked={false} />} label="Position OK" />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={4}>

                    </Grid>
                </Grid>

            </EnterDimFrame>
            <BottomNav onClickPrev={onClickPrev} onClickNext={onClickNext} />

        </AppFrame>
    )
}

/*<h4>Anzahl der Zylinder und Umfänge eingeben</h4>
        <img  src={mainLogo} alt="fireSpot" height={180}/>
        <TextField id="outlined-basic" label="Anzahl Zylinder" variant="outlined" sx={{marginTop: 2}}/>
        <TextField id="outlined-basic" label="U1 [cm]" variant="outlined"  sx={{marginTop: 2}}/>
        <TextField id="outlined-basic" label="U2 [cm]" variant="outlined"  sx={{marginTop: 2}}/> */