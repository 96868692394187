import { Button, Grid } from "@mui/material";
import { AppFrame } from "../components/AppFrame"
import { BottomNav } from "../components/BottomNav";
import { EnterDimFrame } from "../components/EnterDimFrame";
import logo from '../images/logo.svg'
import { useNavigate } from "react-router-dom";

export const Home = () => {

    const navigate = useNavigate();
    
    const onClickNew = () => {
        navigate("/stickCylDim")
    }

    return (
        <AppFrame>
        
            <EnterDimFrame logo={logo} instruction="Willkommen">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Button  variant="outlined">Projekt öffnen</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={onClickNew} variant="outlined">Neues Projekt starten</Button>
                    </Grid>
                </Grid>




            </EnterDimFrame>
           
       
        </AppFrame>
    )
}