import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import logo from './logo.svg';
import './App.css';
import { Home } from './pages/home';
import { StickCylDim } from './pages/stickCylDim';
import { BoomCylDim } from './pages/boomCylDim';
import { SensorSetupWarning } from './pages/sensorSetupWarning';
import { MachinePositioning } from './pages/machinePositioning';
import { SetupLaser } from './pages/setUpLaser';
import { EnterJA } from './pages/EnterJA';
import { EnterJB } from './pages/EnterJB';
import { IntroEnterWK60 } from './pages/IntroEnterWK60';
import { EnterWK60P1 } from './pages/EnterWK60P1';
import { Outro } from './pages/Outro';
import { EnterWK60P2 } from './pages/EnterWK60P2';

function App() {
  return (
    <div className="App">
      <Router>
      <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/stickCylDim"} element={<StickCylDim />} />
      <Route path={"/boomCylDim"} element={<BoomCylDim />} />
      <Route path={"/sensorSetupWarning"} element={<SensorSetupWarning />} />
      <Route path={"/machinePositioning"} element={<MachinePositioning />} />
      <Route path={"/setupLaser"} element={<SetupLaser />} />
      <Route path={"/enterJA"} element={<EnterJA />} />
      <Route path={"/enterJB"} element={<EnterJB />} />
      <Route path={"/introEnterWK60"} element={<IntroEnterWK60 />} />
      <Route path={"/enterWK60p1"} element={<EnterWK60P1 />} />
      <Route path={"/enterWK60p2"} element={<EnterWK60P2 />} />
      <Route path={"/outro"} element={<Outro />} />
      </Routes>
      </Router>


      
    </div>
  );
}

export default App;
